import React from 'react';
import {Divider, Typography} from '@mui/material';

import './SiteResources.scss';

export const DetailedTermsAndCondition = () => {
    return (
        <>
            <Typography variant="h4">Undertaking to Safeguard Confidentiality of Personal Data</Typography>
            <Divider />

            <ol>
                <li>My attention has been drawn to:</li>

                <ol styleName="lowerAlphaListStyle">
                    <li>the Public Sector (Governance) Act 2018;</li>
                    <li>the Personal Data Protection Act 2012;</li>
                    <li>
                        the Computer Misuse Act 1993 and in particular to Part II thereof which sets out offences
                        relating to unauthorised access or modification of computer material;
                    </li>
                    <li>
                        the Official Secrets Act 1935 and in particular to section 5 thereof which relates to
                        safeguarding of official information;{' '}
                    </li>
                    <li>
                        the Income Tax Act 1947 and in particular to section 6 thereof which relates to official secrecy
                        of personal information related to income;{' '}
                    </li>
                    <li>the Children and Young Persons Act 1993;</li>
                    <li>the Vulnerable Adults Act 2018; and</li>
                    <li>the Women&apos;s Charter 1961.</li>
                </ol>

                <li>
                    I understand that in the course of my work with any government department or statutory board, I may
                    acquire or receive personal information, which includes but is not limited to confidential
                    information and data (hereinafter referred to as &quot;confidential information&quot;), which may be
                    protected by laws such as the Public Sector (Governance) Act 2018, Personal Data Protection Act
                    2012, the Computer Misuse Act 1993, and the Income Tax Act 1947. I also acknowledge that all of the
                    personal information is, or is deemed by the Government to be, official information that is covered
                    by the Official Secrets Act 1935.
                </li>

                <li>
                    I undertake to be bounded by the obligations as to protect the confidentiality of personal
                    information; and in particular,
                </li>

                <ol styleName="lowerRomanListStyle">
                    <li>To regard and to deal with all confidential information as secret and confidential;</li>
                    <li>
                        Not to access, monitor, use or process the confidential information or any part thereof except
                        where it is reasonably necessary for the performance of my official duties;{' '}
                    </li>
                    <li>
                        Not to disclose to any person any confidential information without prior written consent from
                        PSD, other than to another person who has provided a similar written undertaking to the
                        Government or permitted by the Government to receive the confidential information and only for
                        the performance of my official duties;
                    </li>
                    <li>
                        Not to publish or broadcast to the public any confidential information without prior written
                        consent from PSD;
                    </li>
                    <li>
                        Not to process, store, access or transfer any confidential information outside Singapore, or
                        allow parties outside Singapore to have access to it, unless with the written consent from PSD
                        and subject to such conditions as PSD may impose;
                    </li>
                    <li>
                        To take all reasonable measures to ensure that the confidential information is protected against
                        loss or damage (whether accidental or otherwise), and against unauthorised access, use,
                        modification, disclosure or other misuse, and that only authorised personnel have access to the
                        data;
                    </li>
                    <li>
                        To return to PSD any and all confidential information, and shall ensure that no part or copies
                        thereof (including electronic records) remains in my possession, whenever required by PSD, or
                        upon the expiration or termination of my employment with my employer, or where such confidential
                        information is no longer required in the course of my duties; and
                    </li>
                    <li>
                        Not to produce in any court any income information, or to divulge or communicate to any court
                        any income information coming under my notice in the performance of my official duties except as
                        may be necessary for the purpose of carrying into effect the provisions of the Income Tax Act,
                        or in order to institute a prosecution, or in the course of a prosecution, for any offence
                        committed in relation to income tax.
                    </li>
                </ol>
                <li>
                    I further understand and agree that any breach or neglect of this undertaking may render me liable
                    to prosecution under the relevant legislation, including but not limited to the Public Sector
                    (Governance) Act 2018, Personal Data Protection Act 2012, the Computer Misuse Act 1993, the Official
                    Secrets Act 1935, the Income Tax Act 1947, the Children and Young Persons Act 1993, the Vulnerable
                    Adults Act 2018, and the Women&apos;s Charter 1961.
                </li>
            </ol>
        </>
    );
};
