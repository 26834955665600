import React from 'react';
import {Grid, Typography} from '@mui/material';
import {ListModel} from '~/components/Common/ListModel/ListModel';
import styles from './HomeOwnership.scss';
import {getDate, formatDate} from '~/utils/dateUtils';
import {isNotEmpty} from '~/utils/contentUtils';

export interface ILastThreeYears {
    DateFrom: string;
    DateTo: string;
    Tenure: string;
    Address: string;
}

export const LastThreeYears: React.FunctionComponent<{data?: ILastThreeYears}> = ({data}) => {
    if (!data) return <></>;
    const dateFrom = (data: ILastThreeYears) =>
        isNotEmpty(data.DateFrom) ? formatDate(getDate(data.DateFrom), false, true) : data.DateFrom;
    const dateTo = (data: ILastThreeYears) =>
        isNotEmpty(data.DateTo) ? formatDate(getDate(data.DateTo), false, true) : data.DateTo;
    const address = (data: ILastThreeYears) => (
        <Typography variant="body2" className={styles.content}>
            {isNotEmpty(data.Address) ? data.Address.toLowerCase() : data.Address}
        </Typography>
    );
    const tenure = (data: ILastThreeYears) => (
        <Typography variant="body2" className={styles.content}>
            {isNotEmpty(data.Tenure) ? data.Tenure.toLowerCase() : data.Tenure}
        </Typography>
    );
    return (
        <Grid item xs={12}>
            <ListModel
                columns={[dateFrom, dateTo, address, tenure]}
                headers={['Date From', 'Date To', 'Address', 'Tenure']}
                modelList={[
                    {
                        Id: 1,
                        DateFrom: data.DateFrom,
                        DateTo: data.DateTo,
                        Address: data.Address,
                        Tenure: data.Tenure,
                    },
                ]}
                modelComparator={[() => 0, () => 0, () => 0, () => 0]}
                variant="expanded"
                sortable={false}
            />
        </Grid>
    );
};
