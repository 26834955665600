import React from 'react';
import {Divider, Typography} from '@mui/material';
import {SUPPORT_EMAIL} from '~/constants';

import './SiteResources.scss';

export const PrivacyStatement = () => {
    return (
        <>
            <Typography variant="h4">Privacy Statement</Typography>
            <Divider />

            <Typography variant="h5">General</Typography>

            <ol>
                <li>This is a Government of Singapore digital service.</li>
                <li>
                    We may use &quot;cookies&quot;, where a small data file is sent to your browser to store and track
                    information about you when you enter our digital service. The cookie is used to track information
                    such as the number of users and their frequency of use, profiles of users and their preferred
                    digital services. While this cookie can tell us when you enter our digital services and which pages
                    you visit, it cannot read data off your hard disk.
                </li>
                <li>
                    You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you
                    can usually modify your browser setting to decline cookies if you prefer. This may prevent you from
                    taking full advantage of the digital service.
                </li>
            </ol>

            <Typography variant="h5">Use of Data</Typography>

            <ol start={4}>
                <li>If you provide us with personal data:</li>
                <ol styleName="lowerAlphaBracketListStyle">
                    <li>
                        Where appropriate, we may share necessary data with other Government agencies, so as to improve
                        the discharge of public functions, and to serve you in the most efficient and effective way
                        unless such sharing is prohibited by law.
                    </li>
                    <li>
                        We may share your personal data with non-Government entities that have been authorised to carry
                        out specific Government services. We will NOT share your personal data with other non-Government
                        entities without your consent except where such sharing complies with the law.
                    </li>
                    <li>
                        For your convenience, we may also display to you data you had previously supplied us or other
                        Government agencies. This will speed up the transaction and save you the trouble of repeating
                        previous submissions. Should the data be out-of-date, please supply us the latest data.
                    </li>
                </ol>
            </ol>

            <Typography variant="h5">Protection of Data</Typography>

            <ol start={5}>
                <li>
                    To safeguard your personal data, all electronic storage and transmission of personal data is secured
                    with appropriate security technologies.
                </li>
                <li>
                    This digital service may contain links to non-Government digital services whose data protection and
                    privacy practices may differ from ours. We are not responsible for the content and privacy practices
                    of these other digital services and we encourage you to consult the privacy notices of those digital
                    services.
                </li>
            </ol>

            <Typography variant="h5">Contact Information</Typography>

            <ol start={7}>
                <li>Please contact PSD OneCV Support ({SUPPORT_EMAIL}) if you:</li>
                <ol styleName="lowerAlphaBracketListStyle">
                    <li>Have any enquires or feedback on our data protection policies and procedures,</li>
                    <li>Need more information on or access to data which you have provided to us in the past.</li>
                </ol>
            </ol>

            <Typography variant="h5">Definitions</Typography>
            <br />
            <Typography variant="body1">
                &quot;Government Agency&quot; refers to an Organ of State, Ministry, Department or Statutory Board.
                <br />
                &quot;Non-Government entity&quot; refers to a person other than a Government Agency.
                <br />
                &quot;Personal data&quot; shall have the same meaning as its definition in the Personal Data Protection
                Act 2012 (No. 26 of 2012).
            </Typography>
        </>
    );
};
